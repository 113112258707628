@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~simple-keyboard/build/css/index.css";

/*@import 'https://fonts.googleapis.com/icon?family=Material+Icons';*/
/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('assets/webfonts/material-icon.woff2') format('woff2');
}

.absolutePos25 {
  position: absolute;
  top: 25rem;
}

.no-scroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important;

}

.modal {
  animation-name: modelAnimation;
  animation-duration: 0.3s;
}

@keyframes modelAnimation {
  0% {
    transform: scale(0.5)
  }

  25% {
    transform: scale(0.75)
  }

  50% {
    transform: scale(0.85)
  }

  100% {
    transform: scale(1)
  }
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

* {
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

/*https://caniuse.com/viewport-units*/
/*As for the reasons listed in the above, especially the known issues tab,
  All attempts should be made to use
            REM    or    %
  in order to ensure future portability and maintanability of the code.
  If two classes exist with the same values in different units, prefer the above*/

/*********************************************************/
/*********************************************************/
/** declarator for dynamic css variables - DO NOT TOUCH **/
/*********************************************************/
/*********************************************************/
:root {
  --accent-color: red;
  --price-color: red;
  --bold-text-color: black;
  --border-color: red;
  --category-background-color: red;
  --category-sidebar-background-color: red;
  --footer-order-summary-background-color: red;
  --negative-button-color: lightgray;
  --negative-button-text-color: black;
  --positive-button-color: black;
  --positive-button-text-color: white;
  --primary-color: blue;
  --secondary-color: red;
  --text-color: black;
  --lime-text: #7fbb04;
}

/************************************************************/
/************************************************************/
/************************Display classes*********************/
/************************************************************/
/************************************************************/
.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.flex1 {
  flex: 1;
}

.grow1 {
  flex-grow: 1;
}

.grow2 {
  flex-grow: 2;
}

.grow3 {
  flex-grow: 3;
}

.grow4 {
  flex-grow: 4;
}

.shrink1 {
  flex-shrink: 1;
}

.shrink2 {
  flex-shrink: 2;
}

.shrink3 {
  flex-shrink: 3;
}

.shrink4 {
  flex-shrink: 4;
}

.grid {
  display: grid;
}

.block {
  display: block;
}

.block-inline {
  display: inline-block;
}

.inline-flex {
  display: inline flex;
}

.inline-grid {
  display: inline grid;
}

.table-display {
  display: table;
}

.table-row-display {
  display: table-row;
}

.list-item-display {
  display: list-item;
}

.flex-right {
  display: flex;
  flex-direction: row;
}

.flex-left {
  display: flex;
  flex-direction: row-reverse;
}

.flex-down {
  display: flex;
  flex-direction: column;
}

.flex-up {
  display: flex;
  flex-direction: column-reverse;
}

.just-contents-center {
  justify-content: center;
}

.just-contents-left {
  justify-content: left;
}

.just-contents-between {
  justify-content: space-between;
}

.just-contents-around {
  justify-content: space-around;
}

.just-contents-even {
  justify-content: space-evenly;
}

.just-contents-stretch {
  justify-content: stretch;
}

.just-contents-flexend {
  justify-content: flex-end
}

.align-center {
  align-items: center;
}

.align-stretch {
  align-items: stretch;
}

/* not used in flexbox layouts */

.just-self-left {
  justify-self: left;
}

.just-self-center {
  justify-self: center;
}

.just-self-right {
  justify-self: right;
}

/*********************************************************/
/**************Line Height Classes************************/
/*********************************************************/
/**********LINE HEIGHT CLASSES SHOULD BE UNITLESS*********/
/*********************************************************/
/*https://developer.mozilla.org/en-US/docs/Web/CSS/line-height*/

.lh1 {
  line-height: 0.1;
}

.lh2 {
  line-height: 0.2;
}

.lh3 {
  line-height: 0.3;
}

.lh4 {
  line-height: 0.4;
}

.lh5 {
  line-height: 0.5;
}

.lh6 {
  line-height: 0.6;
}

.lh7 {
  line-height: 0.7;
}

.lh8 {
  line-height: 0.8;
}

.lh9 {
  line-height: 0.9;
}

.lh10 {
  line-height: 1.0;
}

.lh11 {
  line-height: 1.1;
}

.lh12 {
  line-height: 1.2;
}

.lh13 {
  line-height: 1.3;
}

.lh14 {
  line-height: 1.4;
}

.lh15 {
  line-height: 1.5;
}

.lh16 {
  line-height: 1.6;
}

.lh7 {
  line-height: 1.7;
}

.lh18 {
  line-height: 1.8;
}

.lh19 {
  line-height: 1.9;
}

.lh20 {
  line-height: 2;
}

.lh21 {
  line-height: 2.1;
}

.lh22 {
  line-height: 2.2;
}

.lh23 {
  line-height: 2.3;
}

.lh24 {
  line-height: 2.4;
}

.lh25 {
  line-height: 2.5;
}

.lh26 {
  line-height: 2.6;
}

.lh27 {
  line-height: 2.7;
}

.lh28 {
  line-height: 2.8;
}

.lh29 {
  line-height: 2.9;
}

.lh30 {
  line-height: 3;
}

/************************************************************/
/************************************************************/
/**Static color classes - use sparingly, they will be removed in the future*/
/************************************************************/
/************************************************************/
.black {
  color: #000;
}

.border-black {
  border-color: #000;
}

.background-black {
  background: #000;
}

.white {
  color: #fff;
}

.border-white {
  border-color: #fff;
}

.background-white {
  background: #fff;
}

.gray {
  color: gray;
}

.border-gray {
  border-color: gray;
}

.background-gray {
  background: rgb(128, 128, 128);
}

.light-gray {
  color: lightgray;
}

.border-light-gray {
  border-color: lightgray;
}

.background-light-gray {
  background: lightgray;
}

.red {
  color: #f00;
}

.border-red {
  border-color: #f00;
}

.background-red {
  background: #f00;
}

.green {
  color: green
}

.green-cyan {
  color: #198754;
}

.border-green-cyan {
  border-color: #198754;
}

.background-green-cyan {
  background: #198754;
}

.yellow {
  color: yellow;
}

.border-yellow {
  border-color: yellow;
}

.background.yellow {
  background: yellow;
}

.blue {
  color: blue;
}

.border-blue {
  border-color: blue;
}

.background-blue {
  background: blue;
}

/************************************************************/
/************************************************************/
/** Dynamic color classes - do not use before branch login **/
/************************************************************/
/************************************************************/
/*
  accent-color
  bold-color
  border-color
  category-color
  category-background-color
  footer-color
  negative-button-color
  negative-button-text-color
  positive-button-color
  positive-button-text-color
  primary-color
  secondary-color
  text-color
  /*
  /************************************************************/
/*******************start of accent colors*******************/
/************************************************************/

.accent-color {
  color: var(--accent-color) !important;
}

.border-accent-color {
  border-color: var(--accent-color) !important;
}

.top-border-accent-color {
  border-top-color: var(--accent-color) !important;
}

.right-border-accent-color {
  border-right-color: var(--accent-color) !important;
}

.bottom-border-accent-color {
  border-bottom-color: var(--accent-color) !important;
}

.left-border-accent-color {
  border-left-color: var(--accent-color) !important;
}

.background-accent-color {
  background-color: var(--accent-color) !important;
}

/************************************************************/
/*******************start of bold colors*******************/
/************************************************************/

.bold-color {
  color: var(--bold-text-color) !important;
}

.border-bold-color {
  border-color: var(--bold-text-color) !important;
}

.top-border-bold-color {
  border-top-color: var(--bold-text-color) !important;
}

.right-border-bold-color {
  border-right-color: var(--bold-text-color) !important;
}

.bottom-border-bold-color {
  border-bottom-color: var(--bold-text-color) !important;
}

.left-border-bold-color {
  border-left-color: var(--bold-text-color) !important;
}

.background-bold-color {
  background-color: var(--bold-text-color) !important;
}

/************************************************************/
/*******************start of border colors*******************/
/************************************************************/

.border-color {
  color: var(--border-color) !important;
}

.border-border-color {
  border-color: var(--border-color) !important;
}

.top-border-border-color {
  border-top-color: var(--border-color) !important;
}

.right-border-border-color {
  border-right-color: var(--border-color) !important;
}

.bottom-border-border-color {
  border-bottom-color: var(--border-color) !important;
}

.left-border-border-color {
  border-left-color: var(--border-color) !important;
}

.background-border-color {
  background-color: var(--border-color) !important;
}

/************************************************************/
/***********start of category background colors**************/
/************************************************************/

.category-color {
  color: var(--category-background-color) !important;
}

.border-category-color {
  border-color: var(--category-background-color) !important;
}

.top-border-category-color {
  border-top-color: var(--category-background-color) !important;
}

.right-border-category-color {
  border-right-color: var(--category-background-color) !important;
}

.bottom-border-category-color {
  border-bottom-color: var(--category-background-color) !important;
}

.left-border-category-color {
  border-left-color: var(--category-background-color) !important;
}

.background-category-color {
  background-color: var(--category-background-color) !important;
}

/************************************************************/
/*********start of category sidebar background colors********/
/************************************************************/

.category-background-color {
  color: var(--category-sidebar-background-color) !important;
}

.border-category-background-color {
  border-color: var(--category-sidebar-background-color) !important;
}

.top-border-category-background-color {
  border-top-color: var(--category-sidebar-background-color) !important;
}

.right-border-category-background-color {
  border-right-color: var(--category-sidebar-background-color) !important;
}

.bottom-border-category-background-color {
  border-bottom-color: var(--category-sidebar-background-color) !important;
}

.left-border-category-background-color {
  border-left-color: var(--category-sidebar-background-color) !important;
}

.background-category-background-color {
  background-color: var(--category-sidebar-background-color) !important;
}

/************************************************************/
/*******start of footer order summary background colors******/
/************************************************************/

.footer-color {
  color: var(--footer-order-summary-background-color) !important;
}

.border-footer-color {
  border-color: var(--footer-order-summary-background-color) !important;
}

.top-border-footer-background-color {
  border-top-color: var(--footer-order-summary-background-color) !important;
}

.right-border-footer-background-color {
  border-right-color: var(--footer-order-summary-background-color) !important;
}

.bottom-border-footer-background-color {
  border-bottom-color: var(--footer-order-summary-background-color) !important;
}

.left-border-footer-background-color {
  border-left-color: var(--footer-order-summary-background-color) !important;
}

.background-footer-color {
  background-color: var(--footer-order-summary-background-color) !important;
}

/************************************************************/
/**************start of negative button colors***************/
/************************************************************/

.negative-button-color {
  color: var(--negative-button-color) !important;
}

.border-negative-button-color {
  border-color: var(--negative-button-color) !important;
}

.top-border-negative-button-color {
  border-top-color: var(--negative-button-color) !important;
}

.right-border-negative-button-color {
  border-right-color: var(--negative-button-color) !important;
}

.bottom-border-negative-button-color {
  border-bottom-color: var(--negative-button-color) !important;
}

.left-border-negative-button-color {
  border-left-color: var(--negative-button-color) !important;
}

.background-negative-button-color {
  background-color: var(--negative-button-color) !important;
}

/************************************************************/
/***********start of negative button text colors*************/
/************************************************************/

.negative-button-text-color {
  color: var(--negative-button-text-color) !important;
}

.border-negative-button-text-color {
  border-color: var(--negative-button-text-color) !important;
}

.top-border-negative-button-text-color {
  border-top-color: var(--negative-button-text-color) !important;
}

.right-border-negative-button-text-color {
  border-right-color: var(--negative-button-text-color) !important;
}

.bottom-border-negative-button-text-color {
  border-bottom-color: var(--negative-button-text-color) !important;
}

.left-border-negative-button-text-color {
  border-left-color: var(--negative-button-text-color) !important;
}

.background-negative-button-text-text-color {
  background-color: var(--negative-button-text-color) !important;
}

/************************************************************/
/*************start of positive button colors****************/
/************************************************************/

.positive-button-color {
  color: var(--positive-button-color) !important;
}

.border-positive-button-color {
  border-color: var(--positive-button-color) !important;
}

.top-border-positive-button-color {
  border-top-color: var(--positive-button-color) !important;
}

.right-border-positive-button-color {
  border-right-color: var(--positive-button-color) !important;
}

.bottom-border-positive-button-color {
  border-bottom-color: var(--positive-button-color) !important;
}

.left-border-positive-button-color {
  border-left-color: var(--positive-button-color) !important;
}

.background-positive-button-color {
  background-color: var(--positive-button-color) !important;
}

.secondary-button-color {
  color: var(--secondary-color) !important;
}

.border-secondary-button-color {
  border-color: var(--secondary-color) !important;
}


/************************************************************/
/***********start of positive button text colors*************/
/************************************************************/

.positive-button-text-color {
  color: var(--positive-button-text-color) !important;
}

.border-positive-button-text-color {
  border-color: var(--positive-button-text-color) !important;
}

.top-border-positive-button-text-color {
  border-top-color: var(--positive-button-text-color) !important;
}

.right-border-positive-button-text-color {
  border-right-color: var(--positive-button-text-color) !important;
}

.bottom-border-positive-button-text-color {
  border-bottom-color: var(--positive-button-text-color) !important;
}

.left-border-positive-button-text-color {
  border-left-color: var(--positive-button-text-color) !important;
}

.background-positive-button-text-color {
  background-color: var(--positive-button-text-color) !important;
}

/************************************************************/
/****************start of primary colors*********************/
/************************************************************/

.primary-color {
  color: var(--primary-color) !important;
}

.border-primary-color {
  border-color: var(--primary-color) !important;
}

.top-border-primary-color {
  border-top-color: var(--primary-color) !important;
}

.right-border-primary-color {
  border-right-color: var(--primary-color) !important;
}

.bottom-border-primary-color {
  border-bottom-color: var(--primary-color) !important;
}

.left-border-primary-color {
  border-left-color: var(--primary-color) !important;
}

.background-primary-color {
  background-color: var(--primary-color) !important;
}

/************************************************************/
/*************start of secondary colors****************/
/************************************************************/

.secondary-color {
  color: var(--secondary-color) !important;
}

.border-secondary-color {
  border-color: var(--secondary-color) !important;
}

.top-border-secondary-color {
  border-top-color: var(--secondary-color) !important;
}

.right-border-secondary-color {
  border-right-color: var(--secondary-color) !important;
}

.bottom-border-secondary-color {
  border-bottom-color: var(--secondary-color) !important;
}

.left-border-secondary-color {
  border-left-color: var(--secondary-color) !important;
}

.background-secondary-color {
  background-color: var(--secondary-color) !important;
}

/************************************************************/
/******************start of text colors**********************/
/************************************************************/

.text-color {
  color: var(--text-color) !important;
}

.border-text-color {
  border-color: var(--text-color) !important;
}

.top-border-text-color {
  border-top-color: var(--text-color) !important;
}

.right-border-text-color {
  border-right-color: var(--text-color) !important;
}

.bottom-border-text-color {
  border-bottom-color: var(--text-color) !important;
}

.left-border-text-color {
  border-left-color: var(--text-color) !important;
}

.background-text-color {
  background-color: var(--text-color) !important;
}

.red-color {
  color: red;
}

/************************************************************/
/************************************************************/
/**************End of Dynamic color classes******************/
/************************************************************/
/************************************************************/

/********************************************************/
/********************************************************/
/***** Font weight, size and capitalization classes *****/
/********************************************************/
/********************************************************/
.font-size1 {
  font-size: 0.5rem;
}

.font-size2 {
  font-size: 1rem;
}

.font-size3 {
  font-size: 1.5rem;
}

.font-size4 {
  font-size: 2rem;
}

.font-size5 {
  font-size: 2.5rem;
}

.font-size6 {
  font-size: 3rem;
}

.font-size7 {
  font-size: 3.5rem;
}

.font-size8 {
  font-size: 4rem;
}

.font-size9 {
  font-size: 4.5rem;
}

.font-size10 {
  font-size: 5rem;
}

.font-size11 {
  font-size: 5.5rem;
}

.font-size12 {
  font-size: 6rem;
}

.font-size13 {
  font-size: 6.5rem;
}

.font-size14 {
  font-size: 7rem;
}

.thin {
  font-weight: 100;
}

.extra-light {
  font-weight: 200;
}

.light {
  font-weight: 300;
}

.normal {
  font-weight: 400;
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.extra-bold {
  font-weight: 800;
}

.heavy {
  font-weight: 900;
}

.center-text {
  text-align: center;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

/********************************************************/
/********************************************************/
/****** end of font size and capitalization classes ******/
/********************************************************/
/********************************************************/
/************* corner tranformation classes *************/
/********************************************************/
/********************************************************/
.circular {
  border-radius: 50%;
}

.sharp {
  border-radius: 0;
}

.root-round1 {
  border-radius: 0.5rem;
}

.root-round2 {
  border-radius: 1rem;
}

.root-round3 {
  border-radius: 1.5rem;
}

.root-round4 {
  border-radius: 2rem;
}

.root-round5 {
  border-radius: 2.5rem;
}

.root-round6 {
  border-radius: 3rem;
}

.root-round7 {
  border-radius: 3.5rem;
}

.root-round8 {
  border-radius: 4rem;
}

.root-round9 {
  border-radius: 4.5rem;
}

.root-round10 {
  border-radius: 5rem;
}

.vh1-round {
  border-radius: 0.5vh;
}

.vh2-round {
  border-radius: 1vh;
}

.vh3-round {
  border-radius: 1.5vh;
}

.vh4-round {
  border-radius: 2vh;
}

.vh5-round {
  border-radius: 2.5vh;
}

.vh6-round {
  border-radius: 3vh;
}

.vh7-round {
  border-radius: 3.5vh;
}

.vh8-round {
  border-radius: 4vh;
}

.vh9-round {
  border-radius: 4.5vh;
}

.vh10-round {
  border-radius: 5vh;
}

.vw1-round {
  border-radius: 0.5vw;
}

.vw2-round {
  border-radius: 1vw;
}

.vw3-round {
  border-radius: 1.5vw;
}

.vw4-round {
  border-radius: 2vw;
}

.vw5-round {
  border-radius: 2.5vw;
}

.vw6-round {
  border-radius: 3vw;
}

.vw7-round {
  border-radius: 3.5vw;
}

.vw8-round {
  border-radius: 4vw;
}

.vw9-round {
  border-radius: 4.5vw;
}

.vw10-round {
  border-radius: 5vw;
}

/********************************************************/
/********************************************************/
/********** end of corner transformation classes*********/
/********************************************************/
/********************************************************/

/********************************************************/
/********************************************************/
/************ start of element sizing classes ***********/
/********************************************************/
/********************************************************/
.full-size {
  height: 100%;
  width: 100%;
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100vw;
}

.height5 {
  height: 5%;
}

.min-height5 {
  min-height: 5%;
}

.max-height5 {
  max-height: 5%;
}

.width5 {
  width: 5%;
}

.min-width5 {
  min-width: 5%;
}

.max-width5 {
  max-width: 5%;
}

.size5 {
  width: 5%;
  height: 5%;
}

.min-size5 {
  min-width: 5%;
  min-height: 5%;
}

.max-size5 {
  max-width: 5%;
  max-height: 5%;
}

.height10 {
  height: 10%;
}

.min-height10 {
  min-height: 10%;
}

.max-height10 {
  max-height: 10%;
}

.width10 {
  width: 10%;
}

.min-width10 {
  min-width: 10%;
}

.max-width10 {
  max-width: 10%;
}

.size10 {
  width: 10%;
  height: 10%;
}

.min-size10 {
  min-width: 10%;
  min-height: 10%;
}

.max-size10 {
  max-width: 10%;
  max-height: 10%;
}

.height15 {
  height: 15%;
}

.min-height15 {
  min-height: 15%;
}

.max-height15 {
  max-height: 15%;
}

.width15 {
  width: 15%;
}

.min-width15 {
  min-width: 15%;
}

.max-width15 {
  max-width: 15%;
}

.size15 {
  width: 15%;
  height: 15%;
}

.min-size15 {
  min-width: 15%;
  min-height: 15%;
}

.max-size15 {
  max-width: 15%;
  max-height: 15%;
}

.height20 {
  height: 20%;
}

.min-height20 {
  min-height: 20%;
}

.max-height20 {
  max-height: 20%;
}

.width20 {
  width: 20%;
}

.min-width20 {
  min-width: 20%;
}

.max-width20 {
  max-width: 20%;
}

.size20 {
  width: 20%;
  height: 20%;
}

.min-size20 {
  min-width: 20%;
  min-height: 20%;
}

.max-size20 {
  max-width: 20%;
  max-height: 20%;
}

.height25 {
  height: 25%;
}

.width25 {
  width: 25%;
}

.size25 {
  width: 25%;
  height: 25%;
}

.height30 {
  height: 30%;
}

.width30 {
  width: 30% !important;
}

.size30 {
  width: 30%;
  height: 30%;
}

.height35 {
  height: 35%;
}

.width35 {
  width: 35%;
}

.size35 {
  width: 35%;
  height: 35%;
}

.height40 {
  height: 40%;
}

.width40 {
  width: 40%;
}

.size40 {
  width: 40%;
  height: 40%;
}

.height45 {
  height: 45%;
}

.width45 {
  width: 45%;
}

.size45 {
  width: 45%;
  height: 45%;
}

.height50 {
  height: 50%;
}

.width50 {
  width: 50%;
}

.size50 {
  width: 50%;
  height: 50%;
}

.height55 {
  height: 55%;
}

.width55 {
  width: 55%;
}

.size55 {
  width: 55%;
  height: 55%;
}

.height60 {
  height: 60%;
}

.width60 {
  width: 60%;
}

.size60 {
  width: 60%;
  height: 60%;
}

.height65 {
  height: 65%;
}

.width65 {
  width: 65%;
}

.size65 {
  width: 65%;
  height: 65%;
}

.height70 {
  height: 70%;
}

.width70 {
  width: 70% !important;
}

.min-width70 {
  min-width: 70%;
}

.max-width70 {
  max-width: 70%;
}

.size70 {
  width: 70%;
  height: 70%;
}

.height75 {
  height: 75%;
}

.width75 {
  width: 75%;
}

.size75 {
  width: 75%;
  height: 75%;
}

.height80 {
  height: 80%;
}

.width80 {
  width: 80%;
}

.size80 {
  width: 80%;
  height: 80%;
}

.height85 {
  height: 85%;
}

.width85 {
  width: 85%;
}

.size85 {
  width: 85%;
  height: 85%;
}

.height90 {
  height: 90%;
}

.width90 {
  width: 90%;
}

.size90 {
  width: 90%;
  height: 90%;
}

.height95 {
  height: 95%;
}

.width95 {
  width: 95%;
}

.size95 {
  width: 95%;
  height: 95%;
}

.height100 {
  height: 100%;
}

.width100 {
  width: 100%;
}

.size100 {
  width: 100%;
  height: 100%;
}

.max-width100 {
  max-width: 100%;
}

.max-height100 {
  max-height: 100%;
}

.min-width100 {
  min-width: 100%;
}

.min-height100 {
  min-height: 100%;
}

/********************************************************/
/********************************************************/
/*************** Start of border classes*****************/
/********************************************************/
/********************************************************/
/* given in pixel units, as borders do not typically need to scale.
  Any borders requiring scaling should be made locally and given obvious names
  */
.solid {
  border-style: solid;
}

.dashed {
  border-style: dashed;
}

.dotted {
  border-style: dotted;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

.ridge {
  border-style: ridge;
}

.no-border {
  border: 0;
}

.full-border-size1 {
  border-width: 1px;
}

.top-border-size1 {
  border-top-width: 1px;
}

.right-border-size1 {
  border-right-width: 1px;
}

.bottom-border-size1 {
  border-bottom-width: 1px;
}

.right-border-size1 {
  border-right-width: 1px;
}

.full-border-size2 {
  border-width: 2px;
}

.top-border-size2 {
  border-top-width: 2px;
}

.right-border-size2 {
  border-right-width: 2px;
}

.bottom-border-size2 {
  border-bottom-width: 2px;
}

.right-border-size2 {
  border-right-width: 2px;
}

.full-border-size3 {
  border-width: 3px;
}

.top-border-size3 {
  border-top-width: 3px;
}

.right-border-size3 {
  border-right-width: 3px;
}

.bottom-border-size3 {
  border-bottom-width: 3px;
}

.right-border-size3 {
  border-right-width: 3px;
}

.full-border-size4 {
  border-width: 4px;
}

.top-border-size4 {
  border-top-width: 4px;
}

.right-border-size4 {
  border-right-width: 4px;
}

.bottom-border-size4 {
  border-bottom-width: 4px;
}

.right-border-size4 {
  border-right-width: 4px;
}

.full-border-size5 {
  border-width: 5px;
}

.top-border-size5 {
  border-top-width: 5px;
}

.right-border-size5 {
  border-right-width: 5px;
}

.bottom-border-size5 {
  border-bottom-width: 5px;
}

.right-border-size5 {
  border-right-width: 5px;
}

.full-border-size6 {
  border-width: 6px;
}

.top-border-size6 {
  border-top-width: 6px;
}

.right-border-size6 {
  border-right-width: 6px;
}

.bottom-border-size6 {
  border-bottom-width: 6px;
}

.right-border-size6 {
  border-right-width: 6px;
}

.full-border-size7 {
  border-width: 7px;
}

.top-border-size7 {
  border-top-width: 7px;
}

.right-border-size7 {
  border-right-width: 7px;
}

.bottom-border-size7 {
  border-bottom-width: 7px;
}

.right-border-size7 {
  border-right-width: 7px;
}

.full-border-size8 {
  border-width: 8px;
}

.top-border-size8 {
  border-top-width: 8px;
}

.right-border-size8 {
  border-right-width: 8px;
}

.bottom-border-size8 {
  border-bottom-width: 8px;
}

.right-border-size8 {
  border-right-width: 8px;
}

.full-border-size9 {
  border-width: 9px;
}

.top-border-size9 {
  border-top-width: 9px;
}

.right-border-size9 {
  border-right-width: 9px;
}

.bottom-border-size9 {
  border-bottom-width: 9px;
}

.right-border-size9 {
  border-right-width: 9px;
}

.full-border-size10 {
  border-width: 10px;
}

.top-border-size10 {
  border-top-width: 10px;
}

.right-border-size10 {
  border-right-width: 10px;
}

.bottom-border-size10 {
  border-bottom-width: 10px;
}

.right-border-size10 {
  border-right-width: 10px;
}

.top-border-none {
  border-top-style: none;
}

.right-border-none {
  border-right-style: none;
}

.bottom-border-none {
  border-bottom-style: none;
}

.left-border-none {
  border-left-style: none;
}

.border-hidden {
  border-style: hidden;
}

.top-border-hidden {
  border-top-style: hidden;
}

.right-border-hidden {
  border-right-style: hidden;
}

.bottom-border-hidden {
  border-bottom-style: hidden;
}

.left-border-hidden {
  border-left-style: hidden;
}

.border-dotted {
  border-style: dotted;
}

.top-border-dotted {
  border-top-style: dotted;
}

.right-border-dotted {
  border-right-style: dotted;
}

.bottom-border-dotted {
  border-bottom-style: dotted;
}

.left-border-dotted {
  border-left-style: dotted;
}

.border-dashed {
  border-style: dashed;
}

.top-border-dashed {
  border-top-style: dashed;
}

.right-border-dashed {
  border-right-style: dashed;
}

.bottom-border-dashed {
  border-bottom-style: dashed;
}

.left-border-dashed {
  border-left-style: dashed;
}

.border-solid {
  border-style: solid;
}

.top-border-solid {
  border-top-style: solid;
}

.right-border-solid {
  border-right-style: solid;
}

.bottom-border-solid {
  border-bottom-style: solid;
}

.left-border-solid {
  border-left-style: solid;
}

.border-double {
  border-style: double;
}

.top-border-double {
  border-top-style: double;
}

.right-border-double {
  border-right-style: double;
}

.bottom-border-double {
  border-bottom-style: double;
}

.left-border-double {
  border-left-style: double;
}

.border-groove {
  border-style: groove;
}

.top-border-groove {
  border-top-style: groove;
}

.right-border-groove {
  border-right-style: groove;
}

.bottom-border-groove {
  border-bottom-style: groove;
}

.left-border-groove {
  border-left-style: groove;
}

.border-ridge {
  border-style: ridge;
}

.top-border-ridge {
  border-top-style: ridge;
}

.right-border-ridge {
  border-right-style: ridge;
}

.bottom-border-ridge {
  border-bottom-style: ridge;
}

.left-border-ridge {
  border-left-style: ridge;
}

.border-inset {
  border-style: inset;
}

.top-border-inset {
  border-top-style: inset;
}

.right-border-inset {
  border-right-style: inset;
}

.bottom-border-inset {
  border-bottom-style: inset;
}

.left-border-inset {
  border-left-style: inset;
}

.border-outset {
  border-style: outset;
}

.top-border-outset {
  border-top-style: outset;
}

.right-border-outset {
  border-right-style: outset;
}

.bottom-border-outset {
  border-bottom-style: outset;
}

.left-border-outset {
  border-left-style: outset;
}

/********************************************************/
/********************************************************/
/*****************end of border classes******************/
/********************************************************/
/********************************************************/
/*********** start of margin styling classes*************/
/********************************************************/
/********************************************************/
/*Following excerpt from MDN docs regarding margin shorthand syntax, included for convenience
  /********************************************************/
/********************************************************/
/*When one value is specified, it applies the same margin to all four sides.
  When two values are specified, the first margin applies to the top and bottom, the second to the left and right.
  When three values are specified, the first margin applies to the top, the second to the right and left, the third to the bottom.
  When four values are specified, the margins apply to the top, right, bottom, and left in that order (clockwise).*/
/********************************************************/
/********************************************************/
/*No global classes have been created for  3 or 4 value css margin rules
  No global classes have been created for vh/vw css margin rules
  No global classes have been created for px css margin rules
  Such classes should be made locally in the component scope when the global classes are insufficient
  Similarly, pixel measurements should be avoided whenever possible*/
/********************************************************/
/********************************************************/
.auto-margin {
  margin: auto;
}

.no-margin {
  margin: 0 !important;
}

.even-margin1 {
  margin: 0.5rem;
}

.even-margin2 {
  margin: 1rem;
}

.even-margin3 {
  margin: 1.5rem;
}

.even-margin4 {
  margin: 2rem;
}

.even-margin5 {
  margin: 2.5rem;
}

.even-margin6 {
  margin: 3rem;
}

.even-margin7 {
  margin: 3.5rem;
}

.even-margin8 {
  margin: 4rem;
}

.even-margin9 {
  margin: 4.5rem;
}

.even-margin10 {
  margin: 5rem;
}

.vcentered {
  margin-top: auto;
  margin-bottom: auto;
}

.hcentered {
  margin-left: auto;
  margin-right: auto;
}

.vmargin0 {
  margin-top: 0;
  margin-bottom: 0;
}

.vmargin1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.vmargin2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.vmargin3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.vmargin4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.vmargin5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.vmargin6 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.vmargin7 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.vmargin8 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.vmargin9 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}

.vmargin10 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.hmargin0 {
  margin-left: 0;
  margin-right: 0;
}

.hmargin1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.hmargin2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.hmargin3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.hmargin4 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.hmargin5 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.hmargin6 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.hmargin7 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.hmargin8 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.hmargin9 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}

.hmargin10 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.tmargin-auto {
  margin-top: auto;
}

.tmargin0 {
  margin-top: 0;
}

.tmargin1 {
  margin-top: 0.5rem;
}

.tmargin2 {
  margin-top: 1rem;
}

.tmargin3 {
  margin-top: 1.5rem;
}

.tmargin4 {
  margin-top: 2rem;
}

.tmargin5 {
  margin-top: 2.5rem;
}

.tmargin6 {
  margin-top: 3rem;
}

.tmargin7 {
  margin-top: 3.5rem;
}

.tmargin8 {
  margin-top: 4rem;
}

.tmargin9 {
  margin-top: 4.5rem;
}

.tmargin10 {
  margin-top: 5rem;
}

.rmargin-auto {
  margin-right: auto;
}

.rmargin0 {
  margin-right: 0;
}

.rmargin1 {
  margin-right: 0.5rem;
}

.rmargin2 {
  margin-right: 1rem;
}

.rmargin3 {
  margin-right: 1.5rem;
}

.rmargin4 {
  margin-right: 2rem;
}

.rmargin5 {
  margin-right: 2.5rem;
}

.rmargin6 {
  margin-right: 3rem;
}

.rmargin7 {
  margin-right: 3.5rem;
}

.rmargin8 {
  margin-right: 4rem;
}

.rmargin9 {
  margin-right: 4.5rem;
}

.rmargin10 {
  margin-right: 5rem;
}

.bmargin-auto {
  margin-bottom: auto;
}

.bmargin0 {
  margin-bottom: 0;
}

.bmargin1 {
  margin-bottom: 0.5rem;
}

.bmargin2 {
  margin-bottom: 1rem;
}

.bmargin3 {
  margin-bottom: 1.5rem;
}

.bmargin4 {
  margin-bottom: 2rem;
}

.bmargin5 {
  margin-bottom: 2.5rem;
}

.bmargin6 {
  margin-bottom: 3rem;
}

.bmargin7 {
  margin-bottom: 3.5rem;
}

.bmargin8 {
  margin-bottom: 4rem;
}

.bmargin9 {
  margin-bottom: 4.5rem;
}

.bmargin10 {
  margin-bottom: 5rem;
}

.lmargin-auto {
  margin-left: auto;
}

.lmargin0 {
  margin-left: 0;
}

.lmargin1 {
  margin-left: 0.5rem;
}

.lmargin2 {
  margin-left: 1rem;
}

.lmargin3 {
  margin-left: 1.5rem;
}

.lmargin4 {
  margin-left: 2rem;
}

.lmargin5 {
  margin-left: 2.5rem;
}

.lmargin6 {
  margin-left: 3rem;
}

.lmargin7 {
  margin-left: 3.5rem;
}

.lmargin8 {
  margin-left: 4rem;
}

.lmargin9 {
  margin-left: 4.5rem;
}

.lmargin10 {
  margin-left: 5rem;
}

/* end of margin classes */
/********************************************************/
/********************************************************/
/* start of padding classes */
/********************************************************/
/********************************************************/
/* Following excerpt from MDN docs regarding padding shorthand syntax, included for convenience */
/********************************************************/
/********************************************************/
/*When one value is specified, it applies the same padding to all four sides.
  When two values are specified, the first padding applies to the top and bottom, the second to the left and right.
  When three values are specified, the first padding applies to the top, the second to the right and left, the third to the bottom.
  When four values are specified, the paddings apply to the top, right, bottom, and left in that order (clockwise).*/
/********************************************************/
/********************************************************/
/*No global classes have been created for  3 or 4 value css padding rules
  No global classes have been created for vh/vw css padding rules
  No global classes have been created for px css padding rules
  Such classes should be made locally in the component scope when the global classes are insufficient
  Similarly, pixel measurements should be avoided whenever possible*/
/********************************************************/
/********************************************************/
.no-pad {
  padding: 0;
}

.even-pad1 {
  padding: 0.5rem;
}

.even-pad2 {
  padding: 1rem;
}

.even-pad3 {
  padding: 1.5rem;
}

.even-pad4 {
  padding: 2rem;
}

.even-pad5 {
  padding: 2.5rem;
}

.even-pad6 {
  padding: 3rem;
}

.even-pad7 {
  padding: 3.5rem;
}

.even-pad8 {
  padding: 4rem;
}

.even-pad9 {
  padding: 4.5rem;
}

.even-pad10 {
  padding: 5rem;
}

.vpad0 {
  padding-top: 0;
  padding-bottom: 0;
}

.vpad1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.vpad2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.vpad3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.vpad4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.vpad5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.vpad6 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.vpad7 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.vpad8 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.vpad9 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.vpad10 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.hpad0 {
  padding-left: 0;
  padding-right: 0;
}

.hpad1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.hpad2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.hpad3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.hpad4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.hpad5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.hpad6 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.hpad7 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.hpad8 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.hpad9 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}

.hpad10 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.tpad0 {
  padding-top: 0;
}

.tpad1 {
  padding-top: 0.5rem;
}

.tpad2 {
  padding-top: 1rem;
}

.tpad3 {
  padding-top: 1.5rem;
}

.tpad4 {
  padding-top: 2rem;
}

.tpad5 {
  padding-top: 2.5rem;
}

.tpad6 {
  padding-top: 3rem;
}

.tpad7 {
  padding-top: 3.5rem;
}

.tpad8 {
  padding-top: 4rem;
}

.tpad9 {
  padding-top: 4.5rem;
}

.tpad10 {
  padding-top: 5rem;
}

.rpad0 {
  padding-right: 0;
}

.rpad1 {
  padding-right: 0.5rem;
}

.rpad2 {
  padding-right: 1rem;
}

.rpad3 {
  padding-right: 1.5rem;
}

.rpad4 {
  padding-right: 2rem;
}

.rpad5 {
  padding-right: 2.5rem;
}

.rpad6 {
  padding-right: 3rem;
}

.rpad7 {
  padding-right: 3.5rem;
}

.rpad8 {
  padding-right: 4rem;
}

.rpad9 {
  padding-right: 4.5rem;
}

.rpad10 {
  padding-right: 5rem;
}

.bpad0 {
  padding-bottom: 0;
}

.bpad1 {
  padding-bottom: 0.5rem;
}

.bpad2 {
  padding-bottom: 1rem;
}

.bpad3 {
  padding-bottom: 1.5rem;
}

.bpad4 {
  padding-bottom: 2rem;
}

.bpad5 {
  padding-bottom: 2.5rem;
}

.bpad6 {
  padding-bottom: 3rem;
}

.bpad7 {
  padding-bottom: 3.5rem;
}

.bpad8 {
  padding-bottom: 4rem;
}

.bpad9 {
  padding-bottom: 4.5rem;
}

.bpad10 {
  padding-bottom: 5rem;
}

.lpad0 {
  padding-left: 0;
}

.lpad1 {
  padding-left: 0.5rem;
}

.lpad2 {
  padding-left: 1rem;
}

.lpad3 {
  padding-left: 1.5rem;
}

.lpad4 {
  padding-left: 2rem;
}

.lpad5 {
  padding-left: 2.5rem;
}

.lpad6 {
  padding-left: 3rem;
}

.lpad7 {
  padding-left: 3.5rem;
}

.lpad8 {
  padding-left: 4rem;
}

.lpad9 {
  padding-left: 4.5rem;
}

.lpad10 {
  padding-left: 5rem;
}

/********************************************************/
/********************************************************/
/**************** end of padding classes ****************/
/********************************************************/
/********************************************************/
/*********** start of static background classes *********/
/********************************************************/
/********************************************************/
.bg-none {
  background-color: transparent !important;
}

.bg-white {
  background-color: #fff;
}

.bg-lime {
  background-color: #7fbb04;
}

.border-black {
  border: 1px solid #000;
}

.border-white {
  border: 1px solid #fff;
}

/********************************************************/
/********************************************************/
/*************** end of background classes **************/
/********************************************************/
/********************************************************/

.opacity10 {
  opacity: 1;
}

.opacity9 {
  opacity: 0.9;
}

.opacity8 {
  opacity: 0.8;
}

.opacity7 {
  opacity: 0.7;
}

.opacity6 {
  opacity: 0.6;
}

.opacity5 {
  opacity: 0.5;
}

.opacity4 {
  opacity: 0.4;
}

.opacity3 {
  opacity: 0.3;
}

.opacity2 {
  opacity: 0.2;
}

.opacity1 {
  opacity: 0.1;
}

.opacity0 {
  opacity: 0;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden !important;
}

/********************************************************/
/********************************************************/
/*+-----------------+-----------------+-----------------+
  | Property           | occupies space | consumes clicks |
  +--------------------+----------------+-----------------+
  | opacity: 0         |        ✓       |        ✓       |
  +--------------------+----------------+-----------------+
  | visibility: hidden |        ✓       |        ✗       |
  +--------------------+----------------+-----------------+
  | display: none      |        ✗       |        ✗       |
  +--------------------+----------------+----------------+*/
/********************************************************/
/********************************************************/

.hidden {
  /* -xxx- */
  opacity: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

.slide .check-box input[type="checkbox"] {
  display: none;
}

/* div.modal-dialog {
  display: inline;
  justify-content: center;
} */
div.modal-content {
  /* justify-content: center; */
  border-radius: 20px;
  padding: 5rem;
  /* animation-name: example;
  animation-duration: 0.3s;
  width: 60%;
  margin: 15vh auto; */
}

/* div.modal-body {
  justify-content: center;
} */

.form-group {
  justify-content: center;
}

.carousel-inner {
  position: absolute !important;
  z-index: -10;
}

.delay-1 {
  animation-delay: 0.25s;
}

.delay-2 {
  animation-delay: 0.5s;
}

.delay-3 {
  animation-delay: 0.75s;
}

.delay-4 {
  animation-delay: 1s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@-webkit-keyframes Click_Effect {
  50% {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    opacity: 0;
  }

  99% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}

@keyframes Click_Effect {
  50% {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    opacity: 0;
  }

  99% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}

@keyframes example {
  0% {
    transform: scale(0.5);
  }

  75% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.position-relative {
  position: relative;
}

#snackbar {
  visibility: hidden;
  background-color: #333;
  /* width: 100vh; */
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2147483638;
  /* max */
  bottom: 30px;
  font-size: 30px;
}

#snackbarV2.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

#snackbarV2 {
  visibility: hidden;
  background-color: #333;
  /* width: 100vh; */
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2147483638;
  /* max */
  bottom: 30px;
  font-size: 30px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.responsive {
  width: 100%;
}

/* remove a tag decoration */
a {
  text-decoration: none !important;
}

.mat-slider.mat-slider-horizontal .mat-slider-wrapper {
  top: 18px;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-wrapper {
  height: 12px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-horizontal .mat-slider-track-background,
.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  height: 100%;
}

.mat-slider.mat-slider-horizontal .mat-slider-track-fill {
  background-color: var(--primary-color) !important;
}

.mat-accent .mat-slider-thumb {
  height: 60px;
  width: 60px;
  background-color: var(--primary-color) !important;
  border: solid 2px gray;
  bottom: -35px;
  right: -35px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  background-color: white;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
  width: 50px !important;
  height: 50px !important;
  top: -70px !important;
  right: -35px !important;
  background-color: rgba(74, 143, 255, 0) !important;
}

.mat-slider-thumb {
  transform: scale(1) !important;
}

.mat-slider-thumb-label-text {
  color: var(--primary-color) !important;
  transform: scale(2) rotate(-45deg) !important;
  opacity: 1 !important;
  font-size: 20px !important;
  font-weight: 600 !important;
}

.overflow-y-none {
  overflow-y: hidden;
}

.overflow-x-none {
  overflow-x: hidden;
}

.overflow-none {
  overflow: hidden;
}

.overflowx-scroll {
  overflow-x: scroll;
}

.modal-xl {
  max-width: 1000px !important;
}

.modal-xl .modal-content {
  padding: 0 !important;
}


.comboItemModal {
  height: 100vh !important;
  width: 100vw !important;
}

.comboItemModal .modal-dialog-centered {
  display: block;
  align-items: normal;
}

.comboItemModal .modal-dialog {
  margin: 0 !important;
  max-width: 100vw !important;
}

.comboItemModal .modal-xl {
  max-width: 100vw;
}


@keyframes fadeInSide {
  from {
    opacity: 0;
    -webkit-transform: translateX(40%);
    transform: translateX(40%);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.mul-choice .item-ingredient-animation {
  margin: 12px 26px !important;
}

.item-ingredient-animation {
  opacity: 1;
  animation: fadeInSide 1s ease-in-out 0s forwards;
}

.itemModal.modal-dialog {
  margin: 0 !important;
  max-width: 100% !important;
}

.categoryBold {
  font-weight: 700;
  color: var(--primary-color) !important
}

.nestedModifierModel .modal-content {
  height: 96vh;
}


.nestedModifierModel .modifier-container {
  height: 100% !important;
}

.nestedModifierModel #selections {
  height: 53.5vh !important;
}


.nestedModifierModel .combopack-category {
  justify-content: flex-start !important;
}

.nestedModifierModel .item-footer .row {
  justify-content: space-between;
}

.nestedItemModel .modal-content {
  height: 96vh;
  /*  width: 95%;
  margin: auto;*/
}


.nestedItemModel .modifier-container {
  height: 100%;
}

.nestedItemModel #selections {
  height: 53.5vh !important;
}


.nestedItemModel .combopack-category {
  justify-content: flex-start !important;
}

.nestedItemModel .item-footer .row {
  justify-content: space-between;
}

/*
.nestedItemModel .navigation-combo{
    width: 95% !important;
}
*/
span.indName {
  color: var(--positive-button-color) !important;
  font-weight: 500;
}

.cd-pop.modal-dialog {
  max-width: 550px;
}

.BoxBuilderPopup {
  border: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  height: 100vh !important;
}

.BoxBuilderPopup .BoxBuilderPopup_modelDialog {
  max-width: 100% !important;
  margin: 0;
  height: 100%;
}

.BoxBuilderPopup .BoxBuilderPopup_modelDialog .modal-content {
  overflow: hidden !important;
  max-height: 100vh;
  border-radius: 0;
  height: 100% !important;
}

.cd-pop.modal-dialog .modal-content {
  padding: 30px 15px !important;
  border-radius: 20px !important;
  background-color: #fff !important;
}

.don-list-modal .modal-content {
  height: 100vh;
  border-radius: 0;
}

.show_blank-overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 4100;
  top: 0;
  background-color: rgba(0, 0, 0, 0.60);
}

.cusLoginModalContent {
  display: block !important;
  position: relative !important;
  top: 12rem !important;
  align-items: center !important;
}

.loadingComponent .modal-content {
  background-color: transparent;
  border: 0;
}

.scanContent .modal-content {
  background-color: transparent;
  border: none;
}


.cons-dialog.modal-dialog {
  max-width: 958px;
  margin-top: 124px;
}

.cons-dialog.modal-dialog .modal-content {
  padding: 1rem;
  border-radius: 0 0 20px 20px;
}

.cons-dialog.modal-dialog .modal-content .header {
  text-align: right;
}

.cons-dialog.modal-dialog .modal-content .header button.close-btn {
  border: none !important;
  background: transparent !important;
  color: #3cb4fc;
}

.cons-dialog.modal-dialog .modal-content .header button.close-btn:focus {
  box-shadow: none !important;
}

.cons-dialog.modal-dialog .modal-content .header button.close-btn span.material-icons {
  font-size: 6rem;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal {
  text-align: center;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal h1 {
  font-size: 4.1rem;
  font-weight: 600;
  margin: 0 0 40px 0;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .filter-blocks {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 94%;
  margin: 0 auto 5rem auto;
  flex-wrap: wrap;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .filter-blocks p {
  position: relative;
  font-size: 2.3rem;
  background: var(--primary-color);
  margin-right: 2rem;
  text-align: center;
  padding: 1.5rem 4rem;
  border-radius: 10rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: var(--accent-color);
  min-width: 200px;
  height: 64px;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .filter-blocks p.active {
  background: var(--secondary-color);
  color: var(--positive-button-text-color);
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .filter-blocks p.active button {
  background: transparent;
  border: none;
  position: absolute;
  color: var(--primary-color);
  top: -22px;
  right: -2px;
  display: inline-block;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .filter-blocks p button {
  display: none;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .footer-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .footer-btn button.btn-warning,
.fiter-modal.view-more-brands .back-footer button.btn-warning {
  background: var(--positive-button-color);
  border-radius: 40px;
  padding: 20px 90px;
  color: var(--positive-button-text-color);
  font-size: 24px;
  font-weight: 600;
  border: none;
  box-shadow: none !important;
}

button.btn-warning:focus {
  box-shadow: none !important;
}

.cons-dialog.modal-dialog .modal-content .fiter-modal .footer-btn button.btn-default {
  padding: 20px 90px;
  font-size: 24px;
  font-weight: 600;
  background: transparent;
  color: var(--secondary-color);
}

/* .modal-open .cons-two {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.modal-open .cons-two.cons-two-no-blur{
  -webkit-filter: blur(0);
  filter: blur(0);
} */
.view-more-brands .ck-modal-brands {
  padding: 20px 10px 180px 10px !important;
  max-height: 73vh;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
}

.view-more-brands .ck-modal-brands .brand-box {
  height: 166px;
  width: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px 20px 0;
  padding: 20px 30px;
  position: relative;
}

.view-more-brands .ck-modal-brands .brand-box img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  z-index: 10;
}

.view-more-brands .ck-modal-brands .brand-box .white-bg {
  opacity: .3;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
}


.fiter-modal.view-more-brands .back-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 185px;
  display: flex;
  background: white;
  background: rgb(255 255 255 / 88%);
  align-items: center;
  justify-content: center;
  border-radius: 0 0 20px 20px;
  z-index: 10;
}

.view-more-brands .scroll-btn {
  position: absolute;
  right: 20px;
  bottom: 110px;
  z-index: 10;
  background: #fff;
  border-radius: 120px;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.view-more-brands .scroll-btn span.material-icons {
  font-size: 64px;
  color: var(--secondary-color);
}

/* CK css changes */
.cons-two {
  width: 100%;
  min-height: 100vh;
}

.cons-two .cons-header {
  position: relative;
  width: 100%;
  height: 125px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5rem;
  background-color: var(--primary-color);
  z-index: 1099;
}

.cons-two .cons-header .company-logo img {
  max-height: 9rem;
}

.cons-two .cons-header button.btn.btn-primary {
  font-size: 2rem;
  text-align: center;
  background: transparent;
  border: none;
  font-weight: 600;
}

.cons-two .cons-header button.btn.btn-primary span.material-icons {
  display: block;
  font-size: 5rem;
}

.cons-two .cons-header button.filter-btn {
  background: var(--positive-button-color);
  border: none;
  font-size: 2.4rem;
  font-weight: 600;
  position: absolute;
  top: 0;
  right: 18rem;
  padding: 1.5rem 3rem;
  border-radius: 0 0 1.5rem 1.5rem;
  color: var(--positive-button-text-color);
  display: flex;
  align-items: center;
}

.cons-two .cons-header button.filter-btn svg {
  width: 30px;
  margin-right: 10px;
}

.cons-two .cons-brands {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--category-sidebar-background-color);
}

.cons-two .cons-brands .brans-info {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px 60px 125px 60px;
  background-color: var(--category-sidebar-background-color);
}

.cons-two .cons-brands .brans-info .brands-block {
  height: 200px;
  width: 280px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: 20px;
  font-size: 28px;
  color: #fff;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 60px 60px 0;
  position: relative;
  padding: 20px 30px;
}

.cons-two .cons-brands .brans-info .brands-block img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  z-index: 10;
}

.cons-two .cons-brands .brans-info .brands-block .white-bg {
  opacity: .3;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 20px;
}

.cons-two .cons-brands .brans-info .brands-block:nth-child(3n) {
  margin-right: 0 !important;
}

.cons-two .cons-brands .brands-block p {
  margin: 0;
  text-align: center;
  line-height: 1.2;
  text-transform: uppercase;
}

.cons-two .cons-footer {
  display: flex;
  width: 100%;
}

.cons-two .cons-footer .promo img {
  width: 540px;
  height: 260px;
  object-fit: cover;
}

.cons-two .cons-brands .filter-blocks {
  display: flex;
  width: 100%;
  position: sticky;
  top: 190px;
  background-color: var(--category-sidebar-background-color);
  padding: 40px 20px 10px 20px;
  z-index: 10;
  flex-wrap: wrap;
}

.cons-two .cons-brands .filter-blocks p {
  position: relative;
  font-size: 2.5rem;
  background: #fff;
  margin-right: 2rem;
  text-align: center;
  padding: 1rem 4rem;
  border-radius: 10rem;
  font-weight: 500;
  min-width: 200px;
  height: 64px;
  margin-bottom: 20px;
  line-height: 1.7;
}

.cons-two .cons-brands .filter-blocks p button {
  background: transparent;
  border: none;
  position: absolute;
  color: #3db4fc;
  top: -19px;
  right: 0;
}

.cons-two .cons-brands .filter-blocks p button:focus {
  box-shadow: none;
}

.cons-two .cons-brands .filter-blocks p button svg {
  width: 34px;
  height: auto;
}

.scroll-btn {
  position: fixed;
  right: 20px;
  bottom: 65px;
  z-index: 100;
  background: #fff;
  border-radius: 90px;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scroll-btn span.material-icons {
  font-size: 64px;
  color: var(--secondary-color);
}

.no-result {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-result .img {
  margin-bottom: 10rem;
}

.no-result .img img {
  width: 100%;
  height: auto;
}

.no-result h1 {
  font-size: 6rem;
  font-weight: 700;
  margin: 0 0 3rem 0;
}

.no-result p {
  font-size: 5rem;
  font-weight: 500;
}

.guide-bar {
  padding: 50px 60px 40px 60px;
  z-index: 10;
  position: sticky;
  top: 0;
  background-color: var(--category-sidebar-background-color);
}

.guide-bar .guide-box {
  display: flex;
  align-items: center;
  position: relative;
}

.guide-bar .pos-rel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guide-bar .guide-box:last-child {
  margin-right: 0;
}

.guide-bar .guide-box .img-part {
  margin-right: 20px;
}

.guide-bar .guide-box .text-part h5 {
  font-size: 1.8rem;
  margin: 0;
  line-height: 1.5;
  font-weight: 600;
}

.guide-bar .guide-box::after {
  content: '';
  min-width: 1px;
  height: 100px;
  background: #a7a7a7;
  margin-right: 20px;
  right: 0;
  margin-left: 20px;
  display: inline-block;
  position: relative;
}

.guide-bar .guide-box:last-child::after {
  content: none;
}

.btn.filter-btn:disabled {
  opacity: 1 !important;
}

.btn.filter-btn.do-noth:disabled {
  opacity: .85 !important;
}

.item-list::-webkit-scrollbar {
  display: block !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
.item-list {
  scrollbar-color: #f7f7f7;
}

/* Chrome, Edge, and Safari */
.item-list::-webkit-scrollbar {
  width: 34px !important;
}

.item-list::-webkit-scrollbar-track {
  background-color: #f7f7f7;
  border: 13px solid #fff;
}
.item-list::-webkit-scrollbar-thumb {
  background-color: #eaebed;
  border-radius: 20px;
  border: 7px solid #fff;
}
.terms-modal {
  max-width: 100% !important;
  margin: 0 !important;
}
.terms-modal .modal-content{
  height: 100vh;
  margin: 0;
  padding: 4rem;
  border-radius: 0 !important;
}
.qrcode img{
  width: 330px;
}
.ver-cart .cartFooter{
  height: 58vh;

}